import React, { useEffect, useState } from "react";
import io from "socket.io-client";

const Dashboard = () => {
  const [token, setToken] = useState("");
  console.log(token);

  useEffect(() => {
    // Connect to the socket server at the backend
    const socket = io("http://182.93.94.210:5000", {
      transports: ["websocket"],
    });

    // Join the 'dashboard' room
    socket.emit("join", "dashboard");

    // Listen for the 'new-token' event from the server
    socket.on("new-token", (data) => {
      console.log("Token received:", data);
      setToken(data?.otp); // Set the OTP token received from the backend
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-blue-600">
        {token ? `Token: ${token}` : "Waiting for token..."}
      </h1>
    </div>
  );
};

export default Dashboard;
