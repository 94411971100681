import React from 'react'
import { NavLink } from 'react-router-dom'

const NavBar = () => {
  return (
    <div className='w-full text-center text-slate-800 py-[5rem]'>

        <NavLink to={"/dashboard"} className="mx-auto text-[3rem] font-bold transition-all duration-300 hover:bg-slate-800 text-white bg-slate-950 rounded-xl p-[2rem]">Go To Dashboard</NavLink>
      
    </div>
  )
}

export default NavBar
